
body{
  background-image:url("img/logo512.png");
  color: rgb(38,38,38);
  margin: 10px;
  background-color: lightgrey;

}
h1 {
  font-family: 'Festive', cursive;
  font-size: 60px;
  color: #ad84c5;
  margin: 10px;
}

.App {
  text-align: center;
}

.main {
color:rgb(38,38,38);
font-family: Arial, Helvetica, sans-serif;
font-size: 50px;
  margin: 10px;
}
.beskrivelse{
  background-color: #ffffff;
  color: rgb(38,38,38);
  margin: 10px;
}
.billede{
  max-width:300px;
  max-height:300px;
  border-radius: 20px;
  margin: 10px;
}

.billede_zoom{
  max-width:600px;
  max-height:600px;
  border-radius: 20px;
  border: solid #202020 5px;
  margin: 10px;
  position: fixed;
  /*position: relative;*/
  top: 50%;
  left: 50%;
  margin-top: -200px;
  margin-left: -300px;
  z-index: 10;
}

.menu > span {
  border: solid 2px black;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  font-size: 20px;
  background: rgb(203, 162, 227);
}
.indhold{
  width: 800px;
  background: white;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;

}